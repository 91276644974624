// src/pages/PrivacyPolicy.js
import React from "react";
import "./TermsAndPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="terms-and-policy-container">
      <h1>MindMemo Privacy Policy</h1>
      <p>Last Updated: June 18, 2024</p>
      <p>
        Andeon Apps ("we", "our", "us") is committed to protecting your privacy.
        This Privacy Policy explains how we collect, use, disclose, and
        safeguard your information when you use MindMemo. By using our app, you
        consent to the practices described in this Privacy Policy.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We collect the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> We collect your first name and
          email address when you register for an account via email or Google
          sign-in through Firebase Auth.
        </li>
        <li>
          <strong>Voice Memos:</strong> The voice memos you create may contain
          personal information and are stored securely.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>
          Provide, maintain, and improve our services, including managing your
          account and providing customer support.
        </li>
        <li>Analyze usage patterns to improve user experience.</li>
        <li>Comply with legal obligations and protect our rights.</li>
      </ul>

      <h2>3. Data Sharing</h2>
      <p>
        We do not share your personal information with third parties except in
        the following circumstances:
      </p>
      <ul>
        <li>
          To comply with legal obligations, such as a subpoena or court order.
        </li>
        <li>To protect and defend our rights and property.</li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          service.
        </li>
        <li>
          To protect the personal safety of users of the service or the public.
        </li>
      </ul>

      <h2>4. Data Storage and Security</h2>
      <p>
        Your data is stored securely on Firebase Firestore and Firebase Auth. We
        use reasonable administrative, technical, and physical security measures
        to protect your personal information from unauthorized access, use, or
        disclosure. Data may be retained indefinitely unless otherwise requested
        by the user.
      </p>

      <h2>5. User Rights</h2>
      <p>You have the following rights regarding your personal information:</p>
      <ul>
        <li>
          <strong>Access:</strong> You can request access to the personal
          information we hold about you.
        </li>
        <li>
          <strong>Correction:</strong> You can request that we correct any
          inaccuracies in your personal information.
        </li>
        <li>
          <strong>Deletion:</strong> You can request that we delete your
          personal information. Note that deleting your account does not delete
          your voice memos, which are stored indefinitely.
        </li>
      </ul>

      <h2>6. Cookies and Tracking Technologies</h2>
      <p>MindMemo does not use cookies or other tracking technologies.</p>

      <h2>7. Third-Party Services</h2>
      <p>
        We may use third-party analytics tools to help us understand how users
        interact with MindMemo. These tools may collect usage data in accordance
        with their own privacy policies.
      </p>

      <h2>8. Children’s Privacy</h2>
      <p>
        MindMemo is not intended for use by children under the age of 13. We do
        not knowingly collect personal information from children under 13. If we
        become aware that a child under 13 has provided us with personal
        information, we will delete such information from our records.
      </p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on our app. Your
        continued use of MindMemo after any changes indicates your acceptance of
        the updated Privacy Policy.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at AndeonApps@gmail.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;

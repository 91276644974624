// src/pages/PrivacyPolicy.js
import React from "react";
import "./TermsAndPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="terms-and-policy-container">
      <h1>ChallengeWithFriends Privacy Policy</h1>
      <p>Last Updated: July 30, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        Your privacy is important to us. It is Andeon Apps' policy to respect
        your privacy regarding any information we may collect from you through
        our app, ChallengeWithFriends.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        We only ask for personal information when we truly need it to provide a
        service to you. We collect it by fair and lawful means, with your
        knowledge and consent. We may collect the following data:
      </p>
      <ul>
        <li>Personal identification information (Name, email address, etc.)</li>
        <li>Challenge data (Challenge details, progress, etc.)</li>
        <li>User-generated content (Images, comments, etc.)</li>
      </ul>

      <h2>3. Why We Collect Your Data</h2>
      <p>We collect your data for several reasons:</p>
      <ul>
        <li>To provide and maintain our service</li>
        <li>To notify you about changes to our service</li>
        <li>
          To allow you to participate in interactive features of our app when
          you choose to do so
        </li>
        <li>To provide customer support</li>
        <li>
          To gather analysis or valuable information so that we can improve our
          service
        </li>
        <li>To monitor the usage of our service</li>
      </ul>

      <h2>4. How We Use Your Data</h2>
      <p>We use the information we collect in various ways, including to:</p>
      <ul>
        <li>Operate, maintain, and improve our app</li>
        <li>Understand and analyze how you use our app</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the app, and for marketing and promotional
          purposes
        </li>
      </ul>

      <h2>5. Data Protection</h2>
      <p>
        We value your trust in providing us your personal information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>

      <h2>6. Data Retention</h2>
      <p>
        We retain your data indefinitely unless you delete your account or
        specific challenges. If you delete your account, all challenges you own
        and their associated images will be deleted. However, if you
        participated in challenges owned by other users, the images you
        contributed to those challenges will remain stored until the challenge
        owner deletes the challenge or their account.
      </p>

      <h2>7. Disclosure of Data</h2>
      <p>
        We may disclose your personal information in the good faith belief that
        such action is necessary to:
      </p>
      <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of Andeon Apps</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          Protect the personal safety of users of the Service or the public
        </li>
        <li>Protect against legal liability</li>
      </ul>

      <h2>8. Your Data Protection Rights</h2>
      <p>
        You have the right to access, update or to delete the information we
        have on you. Whenever made possible, you can access, update or request
        deletion of your personal data directly within your account settings
        section. If you are unable to perform these actions yourself, please
        contact us to assist you.
      </p>

      <h2>9. Children's Privacy</h2>
      <p>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact us so
        that we will be able to take necessary actions.
      </p>

      <h2>10. International Data Transfers</h2>
      <p>
        Your information, including personal data, may be transferred to — and
        maintained on — computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction. If you are located
        outside United States and choose to provide information to us, please
        note that we transfer the data, including personal data, to United
        States and process it there.
      </p>

      <h2>11. California Privacy Rights</h2>
      <p>
        If you are a California resident, you have the right to request
        information regarding the disclosure of your personal information to
        third parties for their direct marketing purposes. To make such a
        request, please contact us using the information provided below.
      </p>

      <h2>12. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page and
        updating the "Last Updated" date at the top of this Privacy Policy.
      </p>

      <h2>13. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at info@andeonapps.com.
      </p>
    </div>
  );
};
export default PrivacyPolicy;

// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import TermsAndService from "./pages/mindmemo/TermsAndService";
import PrivacyPolicy from "./pages/mindmemo/PrivacyPolicy";
import TermsAndServiceCWF from "./pages/challengewithfriends/TermsAndService";
import PrivacyPolicyCWF from "./pages/challengewithfriends/PrivacyPolicy";
import NotFound from "./pages/NotFound";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/mindmemo/terms-and-service"
            element={<TermsAndService />}
          />
          <Route path="/mindmemo/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/challengewithfriends/terms-of-service"
            element={<TermsAndServiceCWF />}
          />
          <Route
            path="/challengewithfriends/privacy-policy"
            element={<PrivacyPolicyCWF />}
          />
          <Route path="*" element={<NotFound />} />{" "}
        </Routes>
      </div>
    </Router>
  );
};

export default App;

// src/pages/TermsAndService.js
import React from "react";
import "./TermsAndPolicy.css";

const TermsAndService = () => {
  return (
    <div className="terms-and-policy-container">
      <h1>MindMemo Terms and Service</h1>
      <p>Last Updated: June 18, 2024</p>
      <p>
        Welcome to MindMemo, an emoji-based and voice message journaling app
        provided by Andeon Apps ("we", "our", "us"). By using MindMemo, you
        agree to comply with and be bound by the following terms and conditions
        ("Terms"). If you do not agree to these Terms, you should not use our
        app.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing and using MindMemo, you accept and agree to be bound by
        these Terms and our Privacy Policy. These Terms apply to all visitors,
        users, and others who access or use the Service.
      </p>

      <h2>2. Account Registration</h2>
      <p>
        To use MindMemo, you must create an account using your email or Google
        sign-in, both managed through Firebase Auth. You are responsible for
        maintaining the confidentiality of your account information and for all
        activities that occur under your account. You agree to notify us
        immediately of any unauthorized use of your account or any other breach
        of security.
      </p>

      <h2>3. User Conduct</h2>
      <p>
        You agree not to use MindMemo to create, share, or store any content
        that is offensive, lewd, or inappropriate. You also agree not to:
      </p>
      <ul>
        <li>Violate any applicable law or regulation.</li>
        <li>
          Infringe the intellectual property or other rights of any third party.
        </li>
        <li>
          Transmit any viruses, worms, defects, Trojan horses, or any items of a
          destructive nature.
        </li>
        <li>
          Engage in any activity that could disable, overburden, or impair the
          functioning of MindMemo.
        </li>
      </ul>
      <p>
        We reserve the right to remove any content that we deem to violate these
        Terms or is otherwise inappropriate, and to terminate or suspend your
        account if you engage in any prohibited conduct.
      </p>

      <h2>4. Content Ownership</h2>
      <p>
        The emojis used in MindMemo are selected by us and are not
        user-generated. Voice memos created by users remain their property, but
        by using our app, you grant us a worldwide, non-exclusive, royalty-free
        license to store and use this content as necessary to provide our
        services. You represent and warrant that you have all rights necessary
        to grant us this license for any content you submit to MindMemo.
      </p>

      <h2>5. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your account at any time,
        for any reason, including but not limited to a violation of these Terms.
        Upon termination, your right to use MindMemo will immediately cease. If
        you wish to terminate your account, you may simply discontinue using the
        Service.
      </p>

      <h2>6. Disclaimer of Warranties</h2>
      <p>
        MindMemo is provided "as is" and "as available" without warranties of
        any kind, either express or implied, including, but not limited to,
        implied warranties of merchantability, fitness for a particular purpose,
        or non-infringement. We do not warrant that the service will be
        uninterrupted, secure, or error-free, or that any defects will be
        corrected.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        In no event shall Andeon Apps be liable for any indirect, incidental,
        special, consequential, or punitive damages, or any loss of profits or
        revenues, whether incurred directly or indirectly, or any loss of data,
        use, goodwill, or other intangible losses, resulting from (i) your use
        or inability to use MindMemo; (ii) any unauthorized access to or use of
        our servers and/or any personal information stored therein; (iii) any
        interruption or cessation of transmission to or from the service; (iv)
        any bugs, viruses, Trojan horses, or the like that may be transmitted to
        or through our service by any third party; (v) any errors or omissions
        in any content or for any loss or damage incurred as a result of the use
        of any content posted, emailed, transmitted, or otherwise made available
        through MindMemo.
      </p>

      <h2>8. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of [Your Country/State], without regard to its conflict of law
        principles.
      </p>

      <h2>9. Changes to Terms</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will try to provide at
        least 30 days' notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
        By continuing to access or use our Service after those revisions become
        effective, you agree to be bound by the revised terms.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at
        AndeonApps@gmail.com.
      </p>

      <h2>11. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless Andeon Apps, its
        officers, directors, employees, agents, and affiliates, from and against
        any and all claims, liabilities, damages, losses, and expenses,
        including without limitation reasonable legal and accounting fees,
        arising out of or in any way connected with your access to or use of
        MindMemo, or your violation of these Terms.
      </p>

      <h2>12. Dispute Resolution</h2>
      <p>
        Any disputes arising out of or relating to these Terms or MindMemo will
        be resolved through binding arbitration in accordance with the rules of
        the American Arbitration Association. Any such dispute will be
        arbitrated on an individual basis, and will not be consolidated in any
        arbitration with any claim or controversy of any other party. The
        arbitration will be conducted in [Your City/State], and judgment on the
        arbitration award may be entered into any court having jurisdiction
        thereof.
      </p>

      <h2>13. Age Restrictions</h2>
      <p>
        MindMemo is not intended for use by individuals under the age of 13. By
        using MindMemo, you represent and warrant that you are at least 13 years
        old. If we become aware that a user under the age of 13 has provided us
        with personal information, we will delete such information from our
        files.
      </p>

      <h2>14. Entire Agreement</h2>
      <p>
        These Terms constitute the entire agreement between you and Andeon Apps
        regarding the use of MindMemo, superseding any prior agreements between
        you and Andeon Apps relating to your use of the service.
      </p>
    </div>
  );
};

export default TermsAndService;

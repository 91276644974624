// src/pages/Home.js
import React from "react";

const Home = () => {
  return (
    <div>
      <h1>Welcome to Andeon Apps</h1>
      <AboutUs />
      <AppList />
      <ContactUs />
      <Footer />
    </div>
  );
};

const AboutUs = () => {
  return (
    <div style={styles.sectionContainer}>
      <h2>About Andeon Apps</h2>
      <p>
        At Andeon Apps, we are dedicated to crafting innovative and
        user-friendly iOS applications. Our team of talented developers and
        designers work tirelessly to deliver high-quality apps that meet the
        needs of our users. We believe in the power of technology to improve
        lives and strive to create apps that are both functional and enjoyable
        to use.
      </p>
    </div>
  );
};

const AppList = () => {
  return (
    <div style={styles.sectionContainer}>
      <h2>Our Apps</h2>
      <ul>
        <li>
          <h3>ChallengeWithFriends</h3>
          <p>
            Challenge yourself and your friends with ChallengeWithFriends!
            Create or join challenges, track progress visually, and stay
            motivated together.
          </p>
          <p>
            <a href="/challengewithfriends/privacy-policy" style={styles.link}>
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              href="/challengewithfriends/terms-of-service"
              style={styles.link}
            >
              Terms of Service
            </a>
          </p>
        </li>
      </ul>
    </div>
  );
};

const ContactUs = () => {
  return (
    <div style={styles.contactUsContainer}>
      <h2>Contact Us</h2>
      <p>If you have any questions, feel free to reach out to us at:</p>
      <p>
        <a href="mailto:support@andeonapps.com" style={styles.emailLink}>
          support@andeonapps.com
        </a>
      </p>
    </div>
  );
};

const Footer = () => {
  return (
    <div style={styles.footerContainer}>
      <p>&copy; 2024 Andeon Apps. All rights reserved.</p>
      <p>Andeon Apps | Established 2024</p>
    </div>
  );
};

const styles = {
  sectionContainer: {
    margin: "20px 0",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  contactUsContainer: {
    margin: "20px 0",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  emailLink: {
    color: "#007bff",
    textDecoration: "none",
  },
  footerContainer: {
    marginTop: "20px",
    textAlign: "center",
  },
  link: {
    color: "#007bff",
    textDecoration: "none",
  },
};

export default Home;

// src/pages/TermsAndService.js
import React from "react";
import "./TermsAndPolicy.css";

const TermsOfService = () => {
  return (
    <div className="terms-and-policy-container">
      <h1>ChallengeWithFriends Terms of Service</h1>
      <p>Last Updated: July 30, 2024</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By using ChallengeWithFriends, you agree to these Terms. If you disagree
        with any part of the terms, you may not use our app.
      </p>

      <h2>2. Changes to Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. We will try to
        provide at least 30 days' notice prior to any new terms taking effect.
      </p>

      <h2>3. Access to the Service</h2>
      <p>
        We reserve the right to withdraw or amend our service, and any service
        or material we provide via ChallengeWithFriends, in our sole discretion
        without notice.
      </p>

      <h2>4. User Registration</h2>
      <p>
        You must register for an account in order to access and use
        ChallengeWithFriends. You agree to keep your password confidential and
        will be responsible for all use of your account and password.
      </p>

      <h2>5. User Content</h2>
      <p>
        Our app allows you to post, link, store, share and otherwise make
        available certain information, text, graphics, or other material. You
        are responsible for the content that you post to the app, including its
        legality, reliability, and appropriateness.
      </p>

      <h2>6. Prohibited Uses</h2>
      <p>
        You may use ChallengeWithFriends only for lawful purposes and in
        accordance with these Terms. You agree not to use ChallengeWithFriends
        in any way that violates any applicable national or international law or
        regulation.
      </p>

      <h2>7. Intellectual Property Rights</h2>
      <p>
        Other than the content you own, under these Terms, Andeon Apps and/or
        its licensors own all the intellectual property rights and materials
        contained in ChallengeWithFriends.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        In no event shall Andeon Apps, nor its directors, employees, partners,
        agents, suppliers, or affiliates, be liable for any indirect,
        incidental, special, consequential or punitive damages, including
        without limitation, loss of profits, data, use, goodwill, or other
        intangible losses, resulting from your access to or use of or inability
        to access or use ChallengeWithFriends.
      </p>

      <h2>9. Termination</h2>
      <p>
        We may terminate or suspend your account immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms. Upon termination, your right to use
        ChallengeWithFriends will immediately cease.
      </p>

      <h2>10. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of the State of New York, without regard to its conflict of law
        provisions.
      </p>

      <h2>11. Changes to Service</h2>
      <p>
        We reserve the right to withdraw or amend our service, and any service
        or material we provide via ChallengeWithFriends, in our sole discretion
        without notice. We will not be liable if for any reason all or any part
        of ChallengeWithFriends is unavailable at any time or for any period.
      </p>

      <h2>12. Dispute Resolution</h2>
      <p>
        Any dispute arising from or relating to the subject matter of this
        Agreement shall be finally settled by arbitration in New York, New York,
        using the English language in accordance with the Arbitration Rules and
        Procedures of JAMS then in effect, by one commercial arbitrator with
        substantial experience in resolving intellectual property and commercial
        contract disputes.
      </p>

      <h2>13. Third-Party Links and Content</h2>
      <p>
        Our Service may contain links to third-party websites or services that
        are not owned or controlled by Andeon Apps. We have no control over, and
        assume no responsibility for, the content, privacy policies, or
        practices of any third-party websites or services. You further
        acknowledge and agree that Andeon Apps shall not be responsible or
        liable, directly or indirectly, for any damage or loss caused or alleged
        to be caused by or in connection with the use of or reliance on any such
        content, goods, or services available on or through any such websites or
        services.
      </p>

      <h2>14. Age Restrictions</h2>
      <p>
        ChallengeWithFriends is intended for users of all ages. However, users
        under the age of 13 must have parental consent to use the app. By using
        the app, you represent that you are at least 13 years old or that you
        are using the app with parental consent.
      </p>

      <h2>15. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at
        info@andeonapps.com.
      </p>
    </div>
  );
};
export default TermsOfService;

// src/pages/NotFound.js
import React from "react";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1>Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <a href="/">Go back to the homepage</a>
    </div>
  );
};

export default NotFound;
